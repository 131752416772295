import React from 'react';
import PropTypes from 'prop-types';
import { mediaQuery } from '@lumoslabs/react_lux';
import { css, StyleSheet } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import Metatags from 'components/Metatags';
import Layout from 'components/layout';
import * as enOnly from 'locales/enOnly.json';
import figmentLogo from './images/figmentLogo.svg';
import ctaFigment from './images/ctaFigment.svg';
import section1 from './images/section1.svg';
import section2 from './images/section2.svg';
import section3 from './images/section3.svg';
import section4 from './images/section4.svg';

const appStoreLink = 'https://apps.apple.com/app/figment-by-lumosity/id1142719997';
const helpLink = 'https://www.lumosity.com/zendesk/authentication?return_to=https://lumosityfigment.zendesk.com/hc';
const fontLink = 'https://asset.lumosity.com/resources/static/fonts/Vesterbro-Extrabold-Latin-Lumoslabs.woff2';
const vesterbroFont = {
  fontFamily: 'Vesterbro Extrabold',
  fontWeight: '800',
  src: `url(${fontLink}) format('woff2')`
};

const figmentPurple = '#A83CF6';
const figmentPurpleHover = '#8C2BD0';
const blackSolid = '#000000';
const backgroundColor = '#fbf8ef';
const borderColor = '#D3CFD8';

const styles = StyleSheet.create({
  background: {
    backgroundColor
  },
  logo: {
    margin: 'auto',
    width: '200px',
    [mediaQuery.maxWidth.mobileLarge]: {
      width: '150px'
    }
  },
  logoRow: {
    borderBottom: '1px solid',
    borderColor,
    paddingTop: '20px',
    paddingBottom: '40px',
    marginBottom: '20px'
  },
  row: {
    alignItems: 'center'
  },
  section: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '500px',
    [mediaQuery.maxWidth.desktop]: {
      height: '50vw'
    },
    [mediaQuery.maxWidth.tablet]: {
      height: '90vw'
    },
    [mediaQuery.maxWidth.mobile]: {
      height: '80vw'
    }
  },
  leftTextCol: {
    paddingRight: '5vw',
    [mediaQuery.maxWidth.tablet]: {
      padding: '5vw'
    },
    [mediaQuery.maxWidth.mobile]: {
      padding: '10vw'
    }
  },
  rightTextCol: {
    paddingLeft: '5vw',
    [mediaQuery.maxWidth.tablet]: {
      padding: '5vw'
    },
    [mediaQuery.maxWidth.mobile]: {
      padding: '10vw'
    }
  },
  header: {
    fontFamily: [vesterbroFont],
    fontSize: '4.0em',
    [mediaQuery.maxWidth.desktopSmall]: {
      fontSize: '3em'
    },
    [mediaQuery.maxWidth.mobile]: {
      fontSize: '2.5em'
    },
    lineHeight: '1.0em',
    color: blackSolid
  },
  subheader: {
    paddingTop: '5px',
    color: blackSolid,
    fontSize: '1.3em',
    [mediaQuery.maxWidth.tablet]: {
      fontSize: '1.13em'
    },
    lineHeight: '1.4'
  },
  helpSection: {
    marginTop: '50px',
    [mediaQuery.maxWidth.mobileLarge]: {
      padding: '40px 20px',
      marginTop: '0px'
    },
    padding: '80px',
    textAlign: 'center',
    borderTop: '1px solid',
    borderColor
  },
  contact: {
    paddingTop: '15px',
    paddingRight: '10px'
  },
  contactLink: {
    fontSize: '1.3em',
    [mediaQuery.maxWidth.tablet]: {
      fontSize: '1.13em'
    },
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    textTransform: 'uppercase',

    color: figmentPurple,
    ':hover': {
      color: figmentPurpleHover
    },
    ':focus': {
      color: figmentPurpleHover
    }
  },
  badgeWrapper: {
    padding: '20px 0 40px'
  },
  appStoreBadge: {
    width: '200px',
    [mediaQuery.maxWidth.mobileLarge]: {
      width: '150px'
    },
    borderRadius: '8px',
    backgroundColor: figmentPurple,
    ':hover': {
      backgroundColor: figmentPurpleHover
    },
    ':focus': {
      backgroundColor: figmentPurpleHover
    }
  }
});

const backgroundStyle1 = StyleSheet.create({
  background: {
    backgroundImage: `url(${section1})`
  }
});

const backgroundStyle2 = StyleSheet.create({
  background: {
    backgroundImage: `url(${section2})`
  }
});

const backgroundStyle3 = StyleSheet.create({
  background: {
    backgroundImage: `url(${section3})`
  }
});

const backgroundStyle4 = StyleSheet.create({
  background: {
    backgroundImage: `url(${section4})`
  }
});

export default function FigmentIndex(props) {
  const {
    location,
    pageContext: { locale, pagePath }
  } = props;

  const downloadFigment = (
    <div className={css(styles.badgeWrapper)}>
      <a
        href={appStoreLink}
        data-track
        data-track-location="Figment Landing"
        data-track-link-click="download_figment"
        target="blank"
      >
        <img src={ctaFigment} alt="Download Figment from the App Store" className={css(styles.appStoreBadge)} />
      </a>
    </div>
  );

  return (
    <div className={css(styles.background)}>
      <Layout location={location} locale={locale} footerOptions={{ renderLanguageLinks: false }}>
        <Metatags
          title={enOnly.Figment.title}
          description={enOnly.Figment.description}
          canonical={pagePath}
          locale={locale}
        />
        <Container>
          <Row className={css(styles.logoRow)} xs="12">
            <img src={figmentLogo} alt="Figment Logo" className={css(styles.logo)} />
          </Row>
          <Row className={css(styles.row)}>
            <Col className={css(styles.leftTextCol)} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 2 }}>
              <h1 className={css(styles.header)}>{enOnly.Figment.header1}</h1>
              <p className={css(styles.subheader)}>{enOnly.Figment.subheader1}</p>
              {downloadFigment}
            </Col>
            <Col
              className={css(styles.section, styles.row1, backgroundStyle1.background)}
              md={{ size: 6, order: 2 }}
              xs={{ size: 12, order: 1 }}
            />
          </Row>
          <Row className={css(styles.row)}>
            <Col
              className={css(styles.section, backgroundStyle2.background)}
              md={{ size: 6, order: 1 }}
              xs={{ size: 12, order: 1 }}
            />
            <Col className={css(styles.rightTextCol)} md={{ size: 6, order: 2 }} xs={{ size: 12, order: 2 }}>
              <div className={css(styles.header)}>{enOnly.Figment.header2}</div>
              <p className={css(styles.subheader)}>{enOnly.Figment.subheader2}</p>
            </Col>
          </Row>
          <Row className={css(styles.row)}>
            <Col className={css(styles.leftTextCol)} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 2 }}>
              <div className={css(styles.header)}>{enOnly.Figment.header3}</div>
              <p className={css(styles.subheader)}>{enOnly.Figment.subheader3}</p>
            </Col>
            <Col
              className={css(styles.section, backgroundStyle3.background)}
              md={{ size: 6, order: 2 }}
              xs={{ size: 12, order: 1 }}
            />
          </Row>
          <Row className={css(styles.row)}>
            <Col
              className={css(styles.section, backgroundStyle4.background)}
              md={{ size: 6, order: 1 }}
              xs={{ size: 12, order: 1 }}
            />
            <Col className={css(styles.rightTextCol)} md={{ size: 6, order: 2 }} xs={{ size: 12, order: 2 }}>
              <div className={css(styles.header)}>{enOnly.Figment.header4}</div>
              <p className={css(styles.subheader)}>{enOnly.Figment.subheader4}</p>
              {downloadFigment}
            </Col>
          </Row>
          <Row className={css(styles.row)}>
            <Col xs={12} className={css(styles.helpSection)}>
              <div className={css(styles.header)}>{enOnly.Figment.need_help}</div>
              <div className={css(styles.contact)}>
                <a
                  href={helpLink}
                  data-track
                  data-track-location="Figment Landing"
                  data-track-link-click="figment_help"
                  className={css(styles.contactLink)}
                >
                  {enOnly.Figment.contact_us}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </div>
  );
}

FigmentIndex.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string.isRequired
  })
};

FigmentIndex.defaultProps = {
  pageContext: {
    locale: 'en'
  }
};
